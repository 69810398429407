<template>
  <div class="readme-article">
    <h1 id="财务">3.财务</h1>
    <h2 id="学员课时费设置">3.1学员课时费设置</h2>
    <p>学员课时费设置</p>
    <!-- <p>
      <img src="@/assets/img/recruit/config3-1.png" />
    </p> -->
    <h2 id="交款方式设置">3.2交款方式设置</h2>
    <p>交款方式设置，例如：pos机刷卡、现金支付、银行转帐、微信、支付宝等</p>
    <p>
      <img src="@/assets/img/recruit/config3-2.png" />
    </p>
    <h2 id="教师课费标准">3.3教师课费标准</h2>
    <p>教师课费标准设置</p>
    <!-- <p>
      <img src="@/assets/img/recruit/config3-3.png" />
    </p> -->
    <h2 id="课时扣减规则">3.4课时扣减规则</h2>
    <p>课时扣减规则设置</p>
    <!-- <p>
      <img src="@/assets/img/recruit/config3-4.png" />
    </p> -->
    <h2 id="财务支出项目设置">3.5财务支出项目设置</h2>
    <p>财务支出项目设置，具体示例如下图</p>
    <p>
      <img src="@/assets/img/recruit/config3-5.png" />
    </p>
    <h2 id="支出方式设置">3.6支出方式设置</h2>
    <p>支出方式设置，例如：对公基本户付款、副卡付款、现金付款等</p>
    <p>
      <img src="@/assets/img/recruit/config3-6.png" />
    </p>
    <h2 id="赠课规则">3.7赠课规则</h2>
    <p>赠课规则设置</p>
    <!-- <p>
      <img src="@/assets/img/recruit/config3-7.png" />
    </p> -->
  </div>
</template>

<script>
export default {
  name: "Recruit0-3",
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>
<style scoped>
</style>